import React, { FC } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import FadeIn from 'framers/FadeIn';

import Card, { CardType, CardVariants } from 'components/Card';

import { CardListingProps } from './models.d';

import './CardListing.scss';

const CardListing: FC<CardListingProps> = ({ cards, variant }) => {
  const cardListingClass = classNames('card-listing', {
    [`card-listing--${variant}`]: variant,
  });

  const isArticleVariant = variant === 'article';

  const { ARTICLE_BIG, PRODUCT, CUP } = CardVariants;

  return (
    <div className={cardListingClass}>
      {cards?.length ? (
        <FadeIn>
          <motion.div
            layout
            className="card-listing__items animate--child-fade"
            data-testid="card-listing"
          >
            <AnimatePresence>
              {cards.map(({ id, card, tags, url }: CardType) => {
                const isCupVariant = card.variant === CUP ? CUP : PRODUCT;

                return (
                  <motion.div
                    key={id}
                    layout
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                  >
                    <Card
                      {...{
                        ...card,
                        tags,
                        url,
                        variant: isArticleVariant ? ARTICLE_BIG : isCupVariant,
                      }}
                    />
                  </motion.div>
                );
              })}
            </AnimatePresence>
          </motion.div>
        </FadeIn>
      ) : null}
    </div>
  );
};
export default CardListing;
