import { ButtonProps } from 'common/Button';
import { ImageType } from 'common/Image';
import { CardType } from 'components/Card';

export enum RelatedListVariant {
  OUR_BRANDS = 'our-brands',
  PRODUCTS = 'products',
  ARTICLES = 'articles',
  OUR_ARTICLES = 'our-articles',
  ARTICLES_INTRO = 'articles-intro',
}

export interface RelatedListProps extends RelatedListType {
  className?: string;
}

export type RelatedListType = {
  variant: RelatedListVariant;
  relatedItems: CardType[];
  title?: string;
  backgroundImage?: ImageType;
  button?: ButtonProps;
  color?: string;
};
