import { CardType, CardVariants } from 'components/Card';

import { sortedItemsByOrder } from 'utils/sortedItemsByOrder';

export const articlesVariants = {
  articleIntro: [CardVariants.ARTICLE_BIG, CardVariants.ARTICLE_SMALL, CardVariants.ARTICLE_SMALL],
  ourArticle: [CardVariants.ARTICLE_BIG, CardVariants.ARTICLE_BIG, CardVariants.ARTICLE_BIG],
};

export const handleProductVariant = (products: CardType[]) =>
  sortedItemsByOrder(products).map((product) => product);

export const handleArticleVariant = (articles: CardType[], variant: CardVariants[]) =>
  sortedItemsByOrder(articles).map((article, idx) => {
    // eslint-disable-next-line no-param-reassign
    article.card.variant = variant[idx];

    return article;
  });
