import { ImageStructureMock } from 'common/Image';

export const YoutubeVideoDefaultClassesMock = ['youtube-video', 'test-class'];

export const VideoMock = {
  title: 'video-title',
  url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
  image: ImageStructureMock,
  variant: 'internal',
};

export const YoutubeVideoDefaultPropsMock = {
  className: 'test-class',
  video: VideoMock,
};

export const YoutubeVideoChildrenMock = 'test content';
