import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import Card from 'components/Card';
import YoutubeVideo from 'components/YoutubeVideo';
import CardListing from 'containers/CardListing';
import RelatedList, { RelatedListVariant } from 'containers/RelatedList';
import { articlesVariants, handleArticleVariant } from 'containers/RelatedList/utils';

import './ArticleListingPage.scss';

const ArticlesListingPage: FC<ArticlesListingPageTypes.ArticlesListingProps> = ({
  data: {
    articleListingPage: { urls, seo, video },
    articleBanner: { articleBannerList },
    articleIntro: { articlesIntroList },
    allArticleDetailPage: { articlesList },
  },
}) => (
  <Layout background="sauvignon" className="article-listing-page" {...{ urls, seo }}>
    <YoutubeVideo video={video} />

    <Container className="article-listing-page__content">
      <Card {...articleBannerList?.[0].card} url={articleBannerList?.[0].url} />
      <RelatedList
        variant={RelatedListVariant.ARTICLES_INTRO}
        relatedItems={handleArticleVariant(articlesIntroList, articlesVariants.articleIntro)}
      />
      <CardListing cards={articlesList} variant="article" />
    </Container>
  </Layout>
);

export const query = graphql`
  query (
    $lang: String
    $path: [String]
    $articleBannerUrls: [String]
    $articleIntroUrls: [String]
    $promoArticleUrls: [String]
  ) {
    articleListingPage(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      video {
        ...YoutubeVideoFragment
      }
      cardBanner {
        ...CardFragment
      }
      cardIntro {
        ...CardFragment
      }
    }
    articleBanner: allArticleDetailPage(filter: { url: { in: $articleBannerUrls } }) {
      articleBannerList: nodes {
        ...ArticleCardFragment
      }
    }
    articleIntro: allArticleDetailPage(filter: { url: { in: $articleIntroUrls } }) {
      articlesIntroList: nodes {
        ...ArticleCardFragment
      }
    }
    allArticleDetailPage(filter: { url: { nin: $promoArticleUrls } }) {
      articlesList: nodes {
        ...ArticleCardFragment
      }
    }
  }
`;

export default ArticlesListingPage;
