import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import Button from 'common/Button';
import Icon from 'common/Icon';
import Image from 'common/Image';
import Typography from 'common/Typography';

import { YoutubeVideoProps } from './models.d';

import './YoutubeVideo.scss';

const YoutubeVideo: FC<YoutubeVideoProps> = ({ className, video, isChanged }) => {
  const { title, image, url, variant } = video;
  const [playVideo, setPlayVideo] = useState<boolean>(false);

  const youtubeVideoClasses = classNames(
    'youtube-video',
    { [`youtube-video--${variant}`]: variant },
    { [`youtube-video--play`]: playVideo },
    className
  );

  const videoId = url.replace(/.*watch\?v=/i, '');

  const showVideo = (): void => {
    if (videoId) {
      setPlayVideo(true);
    }
  };

  useEffect(() => {
    setPlayVideo(false);
  }, [isChanged]);

  useEffect(() => {
    if (variant === 'simple') setPlayVideo(true);
  }, [variant]);

  const isSimple = variant === 'simple';
  const isBannerVariant = variant === 'banner';

  return (
    <div className={youtubeVideoClasses} data-testid="youtube-video-item">
      {!isSimple ? (
        <div className="youtube-video__title">
          <Typography
            size={{ base: 25, md: 50 }}
            lineHeight="xsmall"
            color="white"
            weight="bold"
            as={isBannerVariant ? 'h1' : 'h2'}
            align="center"
          >
            {title}
          </Typography>
        </div>
      ) : null}
      <div className="youtube-video__container">
        {playVideo ? (
          <iframe
            data-testid="youtube-frame"
            width="80%"
            height="100%"
            aria-label={title}
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="youtube-video__frame"
          />
        ) : (
          <Button
            ui={{
              variant: 'simple',
              typography: { color: 'white' },
              structureAlias: 'buttonUI',
            }}
            onClick={showVideo}
            className="youtube-video__thumbnail"
          >
            <div className="youtube-video__wrapper">
              {image ? <Image {...image.imageStructure} className="youtube-video__image" /> : null}
            </div>
            <Icon icon="video-play" className="youtube-video__icon" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default YoutubeVideo;
