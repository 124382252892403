import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'common/Button';
import Icon from 'common/Icon';
import Image from 'common/Image';
import Link from 'common/Link';
import Typography, { TypographyProps } from 'common/Typography';
import Card, { CardVariants } from 'components/Card';

import { useScreenService } from 'hooks/useScreenService';

import { RelatedListProps, RelatedListVariant } from './models.d';

import './RelatedList.scss';

const RelatedList: FC<RelatedListProps> = ({
  title,
  relatedItems,
  variant,
  backgroundImage,
  button,
  color,
  className,
}) => {
  const relatedSectionClass = classNames(
    'related',
    {
      [`background--${color}`]: color,
    },
    className
  );
  const relatedListClass = classNames({
    [`related__${variant}`]: variant,
  });

  const { isSm, isMd } = useScreenService();

  const isProductVariant = variant === RelatedListVariant.PRODUCTS;

  const isOurArticlesVariant = variant === RelatedListVariant.OUR_ARTICLES;

  const { ARTICLE_BIG } = CardVariants;

  const titleTypography: TypographyProps = {
    size: { base: 25, xs: 29, md: 60 },
    color: 'deep-koamaru',
    lineHeight: 'small',
    dangerouslySetInnerHTML: title,
    as: 'h2',
  };

  return (
    <section className={relatedSectionClass}>
      <div className={relatedListClass} data-testid="related">
        {title ? (
          <div className="related__title">
            {isOurArticlesVariant && (isSm || isMd) ? (
              <Link url={button?.url} ariaLabel={button?.ariaLabel}>
                <Typography
                  {...titleTypography}
                  align="left"
                  padding={{ top: { base: 'unset', md: 'lg' } }}
                />
              </Link>
            ) : (
              <Typography
                {...titleTypography}
                align={isProductVariant ? 'center' : 'left'}
                padding={
                  isOurArticlesVariant
                    ? { top: { base: 'unset', md: 'lg' } }
                    : { top: { base: 'unset', md: 'unset' } }
                }
              />
            )}
            {isOurArticlesVariant ? (
              <Link url={button?.url} ariaLabel={button?.ariaLabel}>
                <Icon className="related__title-icon" icon="arrow-right" />
              </Link>
            ) : null}
          </div>
        ) : null}
        {button?.label ? (
          <Button {...button} className="related__button">
            {button.label}
          </Button>
        ) : null}
        {relatedItems.map(({ id, url, card }, idx) => {
          const cardVariant = variant === RelatedListVariant.ARTICLES ? ARTICLE_BIG : card.variant;

          return (
            <Card
              className={`related__card related__card-${idx}`}
              key={id}
              {...{ ...card, url, variant: cardVariant }}
            />
          );
        })}
      </div>
      {backgroundImage ? (
        <Image className="related__image" {...backgroundImage.imageStructure} />
      ) : null}
    </section>
  );
};

export default RelatedList;
